import React, { createContext } from "react";

export const StoreContext = createContext({});

export const StoreContextProvider = ({ children }) => {

  const store = {};
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};

export const useStoreContext = () => React.useContext(StoreContext);
