import React from "react";
import { Image } from "react-bootstrap";

const Tokenomics = () => {
  return (
    <div id="tokenomics" class="w-full max-w-screen-lg mx-auto fadeInBottom">
      <div class="text-center flex items-center pt-16">
        <span class="flex-grow border-t border-gray-700"></span>
        <span class="px-4">Tokenomics – The Fun Breakdown</span>
        <span class="flex-grow border-t border-gray-700"></span>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-4 pt-10">
        <div class="p-4">
          <p class="text-[16px] leading-5 mt-2 text-[var(--secondary-color)]">
            <span className="text-[16px] text-white">Total Supply:</span>
            &nbsp;10 Billion MEON Coins
          </p>
          <p class="text-[16px] leading-5 mt-2 text-[var(--secondary-color)]">
            <span className="text-[16px] text-white">Fun with Numbers:</span>
            &nbsp; The Pie Chart
          </p>
          <p class="text-[16px] leading-5 mt-2 text-[var(--secondary-color)]">
            <span className="text-[16px] text-white">Launchpool (40%):</span>
            &nbsp;Kickstarting the fun with initial liquidity. This chunk is all
            about getting things rolling and making some noise!
          </p>
          <p class="text-[16px] leading-5 mt-2 text-[var(--secondary-color)]">
            <span className="text-[16px] text-white">
              Liquidity Pool (50%):
            </span>
            &nbsp;Ensuring there’s enough action on exchanges for smooth
            trading. We’re talking big liquidity for big laughs!
          </p>
          <p class="text-[16px] leading-5 mt-2 text-[var(--secondary-color)]">
            <span className="text-[16px] text-white">
              Community Rewards (10%):
            </span>
            &nbsp;Because you’re amazing! This portion is reserved for giving
            back to our community. Earn rewards by being part of the MEON magic.
          </p>
        </div>

        <div class="p-4">
          <div class="flex justify-center items-center h-full">
            <Image
              src={require("../../assets/images/tokenomics.png")}
              class="max-w-[250px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Tokenomics;
