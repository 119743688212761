import React from "react";

const Features = () => {
  return (
    <>
      <div class="w-full max-w-screen-xl mx-auto home-page mb-16 p-3">
        <div class="w-full max-w-screen-lg mx-auto">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
            <p class="text-[45px] font-bold pt-4 leading-8">Features</p>
            <p class="text-[25px] my-4 leading-8">
              Community-Driven: MEON Coin thrives on the strength and enthusiasm
              of its community. Join us and be part of something big. <br />
              Innovative Technology: Built on a secure and scalable blockchain,
              MEON Coin ensures fast transactions and robust security. <br />
              Fun and Engaging: With a playful spirit and a sense of humor, MEON
              Coin brings a unique twist to the world of cryptocurrencies.{" "}
              <br />
              Rewards and Incentives: Earn rewards and incentives by
              participating in our community activities and holding MEON Coins.{" "}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Features;
