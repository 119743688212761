import React from "react";
import { Image } from "react-bootstrap";
const BuyToken = () => {
  return (
    <div id="buy-token" class="w-full max-w-screen-lg mx-auto">
      <div class="flex items-center pt-16">
        <span class="flex-grow border-t border-gray-700"></span>
        <span class="px-4">How To Buy</span>
        <span class="flex-grow border-t border-gray-700"></span>
      </div>

      <div class="grid bg-transparent border-1 border-solid rounded-2xl border-gray-700 mt-10 py-2 px-8">
        <div class="flex md:flex-row sm:flex-col max-sm:flex-col items-center block rounded-lg shadow-secondary-1 dark:bg-surface-dark">
          <div class="md:w-2/5 md:mr-6">
            <img
              class="rounded-t-lg max-sm:w-28 sm:w-full md:w-40 m-auto p-2"
              src={require("../../assets/images/wallet-development.png")}
              alt=""
            />
          </div>
          <div class="text-surface dark:text-white my-auto md:w-4/5">
            <h5 class="mb-2 text-xl font-medium leading-tight max-sm:text-center">
              Create an account on ONUS Exchange
            </h5>
            <p class="mb-4 text-[16px] leading-5 mt-2 text-[var(--secondary-color)] max-sm:text-center">
              Go to ONUS exchange and Signup account{" "}
              <a
                className="text-[16px] text-blue-500"
                href="https://signup.goonus.io/6277729716113141662?utm_campaign=invite"
                // href="https://signup.goonus.io/6277729716113141662?utm_campaign=invite"
              >
                hear.
              </a>{" "}
              {/* Download metamask or your wallet of choice from the app store or
              google play store for free. Desktop users, download the google
              chrome extension by going to metamask.io. */}
            </p>
          </div>
        </div>
      </div>

      <div class="grid bg-transparent border-1 border-solid rounded-2xl border-gray-700 mt-10 py-2 px-8">
        <div class="flex md:flex-row sm:flex-col max-sm:flex-col items-center block rounded-lg shadow-secondary-1 dark:bg-surface-dark">
          <div class="md:w-2/5 md:mr-6">
            <img
              class="rounded-t-lg max-sm:w-28 sm:w-full md:w-28 m-auto p-2"
              src={require("../../assets/images/bnb.png")}
              alt=""
            />
          </div>
          <div class="text-surface dark:text-white my-auto md:w-4/5">
            <h5 class="mb-2 text-xl font-medium leading-tight max-sm:text-center">
              Get Some BNB
            </h5>
            <p class="mb-4 text-[16px] leading-5 mt-2 text-[var(--secondary-color)] max-sm:text-center">
              Have BNB in your wallet to switch to $MEON. If you don’t have any
              BNB, you can buy directly on metamask, transfer from another
              wallet, or buy on another exchange and send it to your wallet.
            </p>
          </div>
        </div>
      </div>

      {/* <div class="grid bg-transparent border-1 border-solid rounded-2xl border-gray-700 mt-10 py-2 px-8">
        <div
          class="flex md:flex-row sm:flex-col max-sm:flex-col items-center block rounded-lg shadow-secondary-1 dark:bg-surface-dark">
          <div class="md:w-2/5 md:mr-6">
            <img
              class="rounded-t-lg max-sm:w-28 sm:w-full md:w-28 m-auto p-2"
              src={require("../../assets/images/pancake.png")}
              alt="" />
          </div>
          <div class="text-surface dark:text-white my-auto md:w-4/5">
            <h5 class="mb-2 text-xl font-medium leading-tight max-sm:text-center">Go to PancakeSwap</h5>
            <p class="mb-4 text-[16px] leading-5 mt-2 text-[var(--secondary-color)] max-sm:text-center">
              Connect to PancakeSwap. Go to pancakeswap.finance/swap in google chrome or on the browser inside your Metamask app. 
              Connect your wallet. Paste the $MEON token address into PancakeSwap, select MEON, and confirm. 
              When Metamask prompts you for a wallet signature, sign.
            </p>
          </div>
        </div>
      </div> */}

      <div class="grid bg-transparent border-1 border-solid rounded-2xl border-gray-700 mt-10 py-2 px-8">
        <div class="flex md:flex-row sm:flex-col max-sm:flex-col items-center block rounded-lg shadow-secondary-1 dark:bg-surface-dark">
          <div class="md:w-2/5 md:mr-6">
            <img
              class="rounded-t-lg max-sm:w-full sm:w-full md:w-72 m-auto p-2"
              src={require("../../assets/images/switchmeon.png")}
              alt=""
            />
          </div>
          <div class="text-surface dark:text-white my-auto md:w-4/5">
            {/* <h5 class="mb-2 text-xl font-medium leading-tight max-sm:text-center">Switch BNB for $MEON</h5> */}
            <h5 class="mb-2 text-xl font-medium leading-tight max-sm:text-center">
              Swap on ONUS Exchange
            </h5>
            <p class="mb-4 text-[16px] leading-5 mt-2 text-[var(--secondary-color)] max-sm:text-center">
              Access this{" "}
              <a
                className="text-[16px] text-blue-500"
                href="#"
                // href="https://signup.goonus.io/6277729716113141662?utm_campaign=invite"
              >
                Link
              </a>{" "}
              to swap $MEON token.
              <br />
              <span className="text-[16px] text-blue-500">$MEON</span> Token
              address: 0x83581C8D5407bA81AF50314E61D50a6FE4d02267
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BuyToken;
