import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet, Route, Routes } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import "./App.css";
import Web3ReactManager from "./components/Web3ReactManager";
import Connection from "./pages/Connection";
// import Footer from "./components/Footer/Footer";
import Navigation from "./components/Navbar";
import Home from "./pages/home.js";
import { fetchContract } from "./redux/contract/contractAction";
import * as s from "./styles/global";
import { useApplicationContext } from "./context/applicationContext";
import Loader from "./components/Loader";
import { publicSettings } from "./constants/networksInfo";
import About from "./pages/about.js";
import BuyToken from "./pages/buytoken.js";
import Features from "./pages/features.js";
import OurCommunit from "./pages/ourcommunit.js";
import Roadmap from "./pages/roadmap.js";

function App() {
  const dispatch = useDispatch();
  const { chainId, account } = useWeb3React();

  const {
    isAppConfigured,
    domainSettings: {
      networks,
      contracts,
    },
    isDomainDataFetching,
    isAvailableNetwork
    // isDomainDataFetched,
  } = useApplicationContext();

  useEffect(() => {
    if (isAppConfigured) {
      let tmpChainId = publicSettings.defaultNetworkId;
      if (chainId) { tmpChainId = chainId; }

      dispatch(fetchContract(tmpChainId, networks, contracts));
    }
  }, [dispatch, account, chainId, isAppConfigured, networks, contracts]);

  return (
    <div class="main-container">
      <Web3ReactManager>
        <s.Screen>
          {!isAvailableNetwork ?
            <Connection /> :
            isDomainDataFetching ? (
              <s.LoaderWrapper>
                <Loader size="2.8rem" />
              </s.LoaderWrapper>
            ) : (
              <>
                <Navigation />
                <div>

                  <Outlet />
                  <Routes>
                    {/* <Route path="/" element={<Home />} /> */}
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/features" element={<Features />} />
                    <Route path="/buy-token" element={<BuyToken />} />
                    <Route path="/roadmap" element={<Roadmap />} />
                    <Route path="/our-communit" element={<OurCommunit />} />
                  </Routes>

                </div>
                {/* <Footer /> */}
              </>
            )
          }
        </s.Screen>
      </Web3ReactManager >
    </div>

  );
}

export default App;
