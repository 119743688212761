import { useState, useEffect } from 'react';
import { getCurrentDomain } from '../utils/utils';
import { useStorageContract } from './useContract';
import { networks, publicSettings } from "../constants/networksInfo";

const getSettings = (settings) => {
  const contracts = {}
  const networks = {}

  for (const chainId in settings) {
    if (settings.hasOwnProperty(chainId)) {
      contracts[chainId] = {
        IDOFactoryAddress: settings[chainId].IDOFactoryAddress,
        TokenLockerFactoryAddress: settings[chainId].TokenLockerFactoryAddress
      }
      networks[chainId] = {
        webSocketRPC: settings[chainId].webSocketRPC
      }
    }
  }

  const appSettings = {
    contracts, networks,
    ...publicSettings
    // ipfsInfuraDedicatedGateway: process.env.REACT_APP_INFURA_DEDICATED_GATEWAY,
    // ipfsInfuraProjectId: process.env.REACT_APP_INFURA_IPFS_KEY,
    // ipfsInfuraProjectSecret: process.env.REACT_APP_INFURA_IPFS_SECRET,
    // admin: '',
    // projectName: process.env.REACT_APP_PROJECT_NAME,
    // logoUrl: '',
    // socialLinks: [],
    // disableSourceCopyright: process.env.REACT_APP_DISABLE_SOURCE_COPYRIGHT,
    // isLockerEnabled: process.env.REACT_APP_ENABLE_LOCKER,
  }
  // console.log("appSettings: ", appSettings)

  return appSettings
}

export default function useDomainData() {

  const [domainSettings, setDomainSettings] = useState({});
  const [domainDataTrigger, setDomainDataTrigger] = useState(false);
  const triggerDomainData = () => setDomainDataTrigger(!domainDataTrigger);

  const storageContract = useStorageContract();

  const domain = getCurrentDomain();

  useEffect(() => {
    try {
      const settings = getSettings(networks);
      setDomainSettings(settings);
    } catch (error) {
      console.log('fetchDomainData Error: ', error)
    }
  }, [
    domainDataTrigger,
    storageContract,
    domain
  ]);

  return {
    domain,
    domainSettings,
    triggerDomainData,
  };
}
