import React from "react";

const BuyToken = () => {
  return (
    <>
      <div class="w-full max-w-screen-xl mx-auto home-page mb-16 p-3">
        <div class="w-full max-w-screen-lg mx-auto">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
            <p class="text-[45px] font-bold pt-4 leading-8">Buy MEON Token</p>
            <p class="text-[25px] my-4 leading-8">
            Buy on Pancake Swap <a href="https://pancakeswap.finance/" class="text-blue-500 text-[25px]">here</a>
            <br/>
            Buy MEON Coin and Join the Community: Follow us on social media and participate in community events to stay updated and connected            </p>
          </div>
        </div>
      </div>
    </>
  )
};

export default BuyToken;
