import React from "react";
import "../../assets/css/rocket.css";
import logo from "../../assets/logo.png"

const Rocket = () => {
  return (
    <div class='relative fadeInRight'>
      <div class="rocket-container left-0 md:left-[100px]">
        <div class="wing-left"></div>
        <div class="wing-right"></div>
        <div class="tail"></div>

        <div class="capsule">
          <div class="top">
            <div class="shadow"></div>
          </div>
          <div class="base"></div>
        </div>

        <div class="window-big"><img src={logo} alt="icon"/></div>
        <div class="window-small"></div>

        <div class="fire-1"></div>
        <div class="fire-2"></div>
        <div class="fire-3"></div>
        <div class="fire-4"></div>

        <div class="spark-1"></div>
        <div class="spark-2"></div>
        <div class="spark-3"></div>
        <div class="spark-4"></div>

        <div class="star star-1"></div>
        <div class="star star-2"></div>
        <div class="star star-3"></div>
        <div class="star star-4"></div>
        <div class="star star-5"></div>
        <div class="star star-6"></div>
        <div class="star star-7"></div>
        <div class="star star-8"></div>
        <div class="star star-9"></div>
        <div class="star star-10"></div>
        <div class="star star-11"></div>
        <div class="star star-12"></div>
        <div class="star star-13"></div>
        <div class="star star-14"></div>
        <div class="star star-15"></div>
        <div class="star star-16"></div>
        <div class="star star-17"></div>
        <div class="star star-18"></div>
        <div class="star star-19"></div>
        <div class="star star-20"></div>

      </div>
    </div>
  )
};

export default Rocket;
