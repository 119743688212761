import React from "react";
import Rocket from "../components/Animations/rocket";
import { NavLink } from "react-router-dom";
import BuyToken from "../components/Infomation/BuyToken";
import Features from "../components/Infomation/Features";
import About from "../components/Infomation/About";
import Community from "../components/Infomation/Community";
import Roadmap from "../components/Infomation/Roadmap";
import Tokenomics from "../components/Infomation/Tokenomics";

const Home = () => {
  return (
    <>
      <div class="w-full max-w-screen-xl mx-auto home-page mb-16 p-3">
        <div class="w-full max-w-screen-lg mx-auto">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
            <div class="fadeInLeft">
              <p class="text-[45px] font-bold pt-4 leading-8">Meon</p>
              {/* <p class="text-[25px] my-4 leading-8">
                Community-focused launchpad for the next generation crypto gems
              </p> */}
              <p class="text-[18px] mt-4 leading-5 text-[var(--secondary-color)]">
                Discover the Future of Meme Coins. Join the MEON Coin community
                and be part of the next big thing in the crypto world. Fun,
                innovative, and full of potential – MEON Coin is here to make
                waves!{" "}
              </p>

              <div class="pt-8">
                <a
                  // to="/launchpad"
                  href="https://goonus.io/en/open-04-launchpools-total-reward-4000000000-meon/"
                  style={{
                    whiteSpace: "nowrap",
                    backgroundColor: "var(--primary)",
                    padding: 10,
                    borderRadius: 20,
                    fontSize: 16,
                    fontWeight: 700,
                    paddingLeft: 50,
                    paddingRight: 50,
                    textDecoration: "none",
                    color: "var(--card)",
                  }}
                >
                  Join Pools
                  
                </a>
              </div>
            </div>

            <div class="h-[400px] overflow-hidden">
              <Rocket />
            </div>
          </div>
        </div>

        <About />
        <Features />
        <Tokenomics/>
        {/* <BuyToken /> */}
        {/* <WhyCBase /> */}
        <Roadmap />
        <Community />

      </div>
    </>
  );
};

export default Home;
