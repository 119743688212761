import BigNumber from "bignumber.js";
import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import "../../App.css";
import { useApplicationContext } from "../../context/applicationContext";
import styled from "styled-components";
import * as s from "../../styles/global";
import { Web3Status } from "../Web3Status";
import Loader from "../Loader";
import { useWeb3React } from "@web3-react/core";
import { CURRENCY } from "../../assets/images";
import { Paper } from "@mui/material";
import { Link } from "react-router-dom";
import { scroller } from "react-scroll";
import pdfFile from "../../assets/MeonCoin - WhitePaper.pdf";
const NetworkCard = styled(Paper)`
  display: flex;
  justify-content: center;
  padding: 2px 20px;
  font-size: 16px;
  border-radius: 20px !important;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-right: 0.4rem;
  align-items: center;
  justify-content: center;

  & > img,
  span {
    height: ${({ size }) => (size ? size + "px" : "24px")};
    width: ${({ size }) => (size ? size + "px" : "24px")};
  }
`;

const Navigation = () => {
  const {
    chainName,
    baseCurrencySymbol,
    ETHamount,
    isNativeCoinBalanceFetching,
  } = useApplicationContext();

  const { active, chainId } = useWeb3React();

  const scrollToSection = (sectionId) => {
    scroller.scrollTo(sectionId, {
      duration: 0,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  const handleOpenPDF = () => {
    window.open(pdfFile, "_blank");
  };

  // const getNetworkInfo = () => {
  //   const networkImage = CURRENCY[chainId];

  //   return (
  //     chainName &&
  //     active && (
  //       // TODO: make some wrapped card
  //       <NetworkCard elevation={2} title={`${chainName} network`}>
  //         {!!networkImage && (
  //           <IconWrapper size={20}>
  //             <img src={networkImage} alt="network logo" />
  //           </IconWrapper>
  //         )}
  //         {chainName}

  //         {active &&
  //           (isNativeCoinBalanceFetching ? (
  //             <div class="ml-2 mt-1.5">
  //               <Loader />
  //             </div>
  //           ) : (
  //             ` ` +
  //             BigNumber(ETHamount)
  //               .dividedBy(10 ** 18)
  //               .toFormat(5) +
  //             " " +
  //             baseCurrencySymbol
  //           ))}
  //       </NetworkCard>
  //     )
  //   );
  // };

  return (
    <Navbar collapseOnSelect expand="lg" variant="dark" style={{ padding: 16 }}>
      <Container style={{ maxWidth: "100%" }}>
        <Link to="/">
          <s.LogoTitle
            src={require("../../assets/logo.png")}
            style={{ height: "35px" }}
          />
        </Link>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto m-auto">
            <Link
              to={{ pathname: "/", hash: "#section1" }}
              onClick={() => scrollToSection("about")}
            >
              <Nav.Link as="span">About</Nav.Link>
            </Link>
            <Link to="/" onClick={() => scrollToSection("features")}>
              <Nav.Link as="span">Features</Nav.Link>
            </Link>
            {/* <Link to="/" onClick={() => scrollToSection("buy-token")}>
              <Nav.Link as="span">Buy Token</Nav.Link>
            </Link> */}
            <Link to="/" onClick={() => scrollToSection("tokenomics")}>
              <Nav.Link as="span">Tokenomics</Nav.Link>
            </Link>
            <Link to="/" onClick={() => scrollToSection("roadmap")}>
              <Nav.Link as="span">Roadmap</Nav.Link>
            </Link>
            <Link to="/" onClick={() => scrollToSection("our-community")}>
              <Nav.Link as="span">Our Community</Nav.Link>
            </Link>

            <Link
              // to="#"
              onClick={handleOpenPDF}
            >
              <Nav.Link as="span">White Paper</Nav.Link>
            </Link>

            {/* <LinkContainer to="/about">
              <Nav.Link>About</Nav.Link>
            </LinkContainer>

            <LinkContainer to="/features">
              <Nav.Link>Features</Nav.Link>
            </LinkContainer>

            <LinkContainer to="/buy-token">
              <Nav.Link>Buy Token</Nav.Link>
            </LinkContainer>

            <LinkContainer to="/roadmap">
              <Nav.Link>Roadmap</Nav.Link>
            </LinkContainer>
           
            <LinkContainer to="/our-communit">
              <Nav.Link>Our Communit</Nav.Link>
            </LinkContainer> */}
          </Nav>

          {/* <Nav>
            <Nav.Link>{getNetworkInfo()}</Nav.Link>
          </Nav> */}

          {/* <Web3Status /> */}
          {/* <a
            href="https://pancakeswap.finance/"
            target="blank"
            class="flex items-center bg-[var(--primary)] text-white text-[16px] px-3 rounded-full h-[36px]"
          >
            Buy Now
          </a> */}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
export default Navigation;
