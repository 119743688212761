import React from "react";

const OurCommunit = () => {
  return (
    <>
      <div class="w-full max-w-screen-xl mx-auto home-page mb-16 p-3">
        <div class="w-full max-w-screen-lg mx-auto">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
            <p class="text-[45px] font-bold pt-4 leading-8">Our Community</p>
            <p class="text-[25px] my-4 leading-8">
              Be part of the MEON Coin family! Follow us on social media, join
              our forums, and participate in discussions. Whether you're a
              seasoned crypto enthusiast or new to the world of
              cryptocurrencies, our community is here to support you.{" "}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurCommunit;
