import React from "react";
import { Image } from "react-bootstrap";
const Features = () => {
  return (
    <div id="features" class="w-full max-w-screen-lg mx-auto fadeInBottom">
      <div class="flex items-center pt-16">
        <span class="flex-grow border-t border-gray-700"></span>
        <span class="px-4">Features</span>
        <span class="flex-grow border-t border-gray-700"></span>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-4 pt-10">
        <div class="p-4">
          <div class="flex justify-center items-center h-full">
            {/* <SimpleProcess /> */}
            <Image
              src={require("../../assets/images/Icon_Simple_Process.png")}
              class="max-w-[250px]"
            />
          </div>
        </div>

        <div class="p-4">
          <p class="text-[25px]">Why Choose MEON Coin?</p>
          <p class="text-[16px] leading-5 mt-2 text-[var(--secondary-color)]">
            Community-Driven: MEON Coin thrives on the strength and enthusiasm
            of its community. Join us and be part of something big.{" "}
          </p>
          <p class="text-[16px] leading-5 mt-2 text-[var(--secondary-color)]">
            Innovative Technology: Built on a secure and scalable blockchain,
            MEON Coin ensures fast transactions and robust security.{" "}
          </p>
          <p class="text-[16px] leading-5 mt-2 text-[var(--secondary-color)]">
            Fun and Engaging: With a playful spirit and a sense of humor, MEON
            Coin brings a unique twist to the world of cryptocurrencies.{" "}
          </p>
          <p class="text-[16px] leading-5 mt-2 text-[var(--secondary-color)]">
            Rewards and Incentives: Earn rewards and incentives by participating
            in our community activities and holding MEON Coins.{" "}
          </p>
        </div>

        {/* <div class="p-4">
          <p class="text-[25px]">Fair for all</p>
          <p class="text-[16px] leading-5 mt-2 text-[var(--secondary-color)]">
            Opportunities to invest in your favorite gems should be distributed
            fairly in a transparent manner. KrystalGO is commited to share IDO
            opportunities for all investors regardless of their investment size.
          </p>
        </div> */}

        {/* <div class="p-4">
          <div class="flex justify-center items-center h-full">
            <Image
              src={require("../../assets/images/Icon_Fair_for_all.png")}
              class="max-w-[250px]"
            />
          </div>
        </div> */}

        {/* <div class="p-4 md:order-2">
          <p class="text-[25px]">Know your Gem</p>
          <p class="text-[16px] leading-5 mt-2 text-[var(--secondary-color)]">
            As an investor, it is key to know what you are getting into. Events
            will be organised by Krystal in collaboration with projects, where
            the community will get a chance to learn more about the projects in
            the form of AMAs, Learn & Earns, Social Quests etc.
          </p>
        </div> */}
        
        {/* <div class="p-4 md:order-1">
          <div class="flex justify-center items-center h-full">
            <Image
              src={require("../../assets/images/know_your_gem.png")}
              class="max-w-[250px]"
            />
          </div>
        </div> */}
      </div>
    </div>
  );
};
export default Features;
