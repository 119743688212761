import React, { createContext, useEffect, useState } from "react";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import { SUPPORTED_CHAIN_IDS } from "../connectors";
import { useLockerFactoryContract, useIDOFactoryContract } from "../hooks/useContract";
import { networks, publicSettings } from "../constants/networksInfo";
import useDomainData from "../hooks/useDomainData";

export const Application = createContext({});

export const ApplicationContextProvider = ({ children }) => {
  const { account, chainId, library, error } = useWeb3React();

  const [targetChainId, setTargetChainId] = useState(chainId ? chainId : publicSettings.defaultNetworkId);

  const [chainName, setChainName] = useState(networks[targetChainId]?.name);
  const [baseCurrencySymbol, setBaseCurrencySymbol] = useState(networks[targetChainId]?.baseCurrency?.symbol);
  const [networkExplorer, setNetworkExplorer] = useState(networks[targetChainId]?.explorer);

  const [isAvailableNetwork, setIsAvailableNetwork] = useState(true);

  const {
    domain,
    isAdmin,
    domainSettings,
    isDomainDataFetching,
    triggerDomainData,
  } = useDomainData();

  const [IDOFactoryAddress, setIDOFactoryAddress] = useState(domainSettings?.contracts?.[targetChainId]?.IDOFactoryAddress || '');
  const [TokenLockerFactoryAddress, setTokenLockerFactoryAddress] = useState(domainSettings?.contracts?.[targetChainId]?.TokenLockerFactoryAddress || '');

  const [isAppConfigured, setIsAppConfigured] = useState(Boolean(
     domainSettings?.contracts?.[targetChainId]?.IDOFactoryAddress
    && domainSettings?.contracts?.[targetChainId]?.TokenLockerFactoryAddress
    && domainSettings?.networks?.[targetChainId]?.webSocketRPC
    && domainSettings?.ipfsInfuraDedicatedGateway
    && domainSettings?.ipfsInfuraProjectId
    && domainSettings?.ipfsInfuraProjectSecret
  ));


  useEffect(() => {
    if (chainId) { // default chain id
      setTargetChainId(chainId)
      setChainName(networks[chainId]?.name)
      setBaseCurrencySymbol(networks[chainId]?.baseCurrency?.symbol)
      setNetworkExplorer(networks[chainId]?.explorer)
      setIDOFactoryAddress(domainSettings?.contracts?.[chainId]?.IDOFactoryAddress || '');
      setTokenLockerFactoryAddress(domainSettings?.contracts?.[chainId]?.TokenLockerFactoryAddress || '');

      setIsAppConfigured(Boolean(
         domainSettings?.contracts?.[chainId]?.IDOFactoryAddress
        && domainSettings?.contracts?.[chainId]?.TokenLockerFactoryAddress
        && domainSettings?.networks?.[chainId]?.webSocketRPC
        && domainSettings?.ipfsInfuraDedicatedGateway
        && domainSettings?.ipfsInfuraProjectId
        && domainSettings?.ipfsInfuraProjectSecret
      ))
    } else {
      setTargetChainId(publicSettings.defaultNetworkId)
      setChainName(networks[publicSettings.defaultNetworkId]?.name)
      setBaseCurrencySymbol(networks[publicSettings.defaultNetworkId]?.baseCurrency?.symbol)
      setNetworkExplorer(networks[publicSettings.defaultNetworkId]?.explorer)
      setIDOFactoryAddress(domainSettings?.contracts?.[targetChainId]?.IDOFactoryAddress || '');
      setTokenLockerFactoryAddress(domainSettings?.contracts?.[targetChainId]?.TokenLockerFactoryAddress || '');

      setIsAppConfigured(Boolean(
         domainSettings?.contracts?.[targetChainId]?.IDOFactoryAddress
        && domainSettings?.contracts?.[targetChainId]?.TokenLockerFactoryAddress
        && domainSettings?.networks?.[targetChainId]?.webSocketRPC
        && domainSettings?.ipfsInfuraDedicatedGateway
        && domainSettings?.ipfsInfuraProjectId
        && domainSettings?.ipfsInfuraProjectSecret
      ))
    }

  }, [domainSettings, chainId, targetChainId])


  useEffect(() => {
    if (error && error instanceof UnsupportedChainIdError) {
      return setIsAvailableNetwork(false);
    }

    if (chainId) {
      setIsAvailableNetwork(
        Boolean(SUPPORTED_CHAIN_IDS.includes(Number(chainId))
        ))
    }
  }, [
    chainId,
    account,
    error,
  ]);

  const [shouldUpdateAccountData, setShouldUpdateAccountData] = useState(false);
  const triggerUpdateAccountData = () => setShouldUpdateAccountData(!shouldUpdateAccountData);


  const [nativeCoinBalance, setNativeCoinBalance] = useState(0);
  const [isNativeCoinBalanceFetching, setIsNativeCoinBalanceFetching] = useState(false);

  useEffect(() => {
    const fetchNativeCoinBalance = async () => {
      setIsNativeCoinBalanceFetching(true);

      try {
        const accountBalance = await library.getBalance(account);
        setNativeCoinBalance(Number(accountBalance));
      } catch (error) {
        console.log('fetchNativeCoinBalance Error: ', error);
      } finally {
        setIsNativeCoinBalanceFetching(false);
      }
    }

    if (account && library && chainId) {
      fetchNativeCoinBalance()
    } else {
      setNativeCoinBalance(0);
    }
  }, [account, library, chainId, shouldUpdateAccountData])


  const TokenLockerFactoryContract = useLockerFactoryContract(TokenLockerFactoryAddress, true);
  const IDOFactoryContract = useIDOFactoryContract(IDOFactoryAddress, true);

  const value = {
    isAppConfigured,
    domain,
    isAdmin,
    domainSettings,
    isDomainDataFetching,
    triggerDomainData,
    isAvailableNetwork,
    chainName,
    networkExplorer,
    baseCurrencySymbol,
    triggerUpdateAccountData,
    ETHamount: nativeCoinBalance,
    isNativeCoinBalanceFetching,
    IDOFactoryContract,
    IDOFactoryAddress,
    TokenLockerFactoryAddress,
    TokenLockerFactoryContract,
  };

  return (
    <Application.Provider value={value}>{children}</Application.Provider>
  );
};

export const useApplicationContext = () => React.useContext(Application);
