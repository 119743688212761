import React from "react";
import logo from "../assets/logo.png";

const About = () => {
  return (
    <>
      <div class="w-full max-w-screen-xl mx-auto home-page mb-16 p-3">
        <div class="w-full max-w-screen-lg mx-auto">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
            <div class="h-full flex flex-col justify-between">
              <p class="text-[45px] font-bold pt-4 leading-8">
                About MEON Coin
              </p>
                <div class="ball w-1/3">
                  <img src={logo} class="w-full" alt="icon"/>
                </div>
            </div>
            <p
              class="text-
            [25px] my-4 leading-8"
            >
              MEON Coin is not just another cryptocurrency; it's a revolution in
              the world of meme coins. With a vibrant community and a playful
              spirit, MEON Coin combines the best of crypto technology with the
              fun and excitement of internet memes. Our mission is to create a
              valuable, community-driven coin that stands out in the crowded
              crypto market.{" "}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
