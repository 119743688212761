import React, { createContext } from "react";

export const PoolContext = createContext({});

export const PoolContextProvider = ({ children }) => {

  const value = {};
  return <PoolContext.Provider value={value}>{children}</PoolContext.Provider>;
};

export const usePoolContext = () => React.useContext(PoolContext);
