export const networks = {
  56: {
    name: "BSC",
    rpc: "https://bscrpc.com/",
    webSocketRPC: '',
    chainId: 56,
    explorer: "https://bscscan.com",
    color: "#CC9B00",
    storage: "0xa7472f384339D37EfE505a1A71619212495A973A",
    multicall: "0x41263cBA59EB80dC200F3E2544eda4ed6A90E76C",
    fromBlock: 25825000,
    baseCurrency: {
      decimals: 18,
      name: "BNB",
      symbol: "BNB"
    },
    wrappedToken: {
      address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      name: "Wrapped BNB",
      symbol: "WBNB"
    },
  },
  97: {
    name: "BSC Testnet",
    rpc: "https://data-seed-prebsc-2-s3.binance.org:8545",
    webSocketRPC: '',
    chainId: 97,
    explorer: "https://testnet.bscscan.com/",
    color: "#CC9B00",
    multicall: "0x6e5BB1a5Ad6F68A8D7D6A5e47750eC15773d6042",
    fromBlock: 27113000,
    baseCurrency: {
      decimals: 18,
      name: "BNB",
      symbol: "BNB"
    },
    wrappedToken: {
      address: "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
      name: "Wrapped BNB",
      symbol: "WBNB"
    },
  },
}

export const publicSettings = {
  ipfsInfuraProjectId: '2TPbHoUnyAv4Jt2PTpO66Q9odyB',
  ipfsInfuraProjectSecret: '9cb775d2b490764777e64c1630806b68',
  ipfsInfuraDedicatedGateway: '',
  isLockerEnabled: true,
  disableSourceCopyright: false,
  projectName: 'MEO',
  logoUrl: '',
  socialLinks: [],
  defaultNetworkId: 56
}

export const chainRouter = {
  56: [
    {
      name: "PancakeSwap",
      FACTORY: "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73",
      WETH: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      ROUTER: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    },
  ],
  97: [
    {
      name: "PancakeSwap",
      FACTORY: "0x6725F303b657a9451d8BA641348b6761A6CC7a17",
      WETH: "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
      ROUTER: "0xD99D1c33F9fC3444f8101754aBC46c52416550D1",
    },
  ],
};


export default {
  chainRouter,
  networks,
  publicSettings
}
