import React from "react";
// import roadmapCss from "./style/roadmap.css"
const Roadmap = () => {
  return (
    <>
      <div class="w-full max-w-screen-xl mx-auto home-page mb-16 p-3">
        <div class="w-full max-w-screen-lg mx-auto">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
            <p class="text-[45px] font-bold pt-4 leading-8">Roadmap</p>
            <p class="text-[25px] my-4 leading-8">
              MEON Coin is not just another cryptocurrency; it's a revolution in
              the world of meme coins. With a vibrant community and a playful
              spirit, MEON Coin combines the best of crypto technology with the
              fun and excitement of internet memes. Our mission is to create a
              valuable, community-driven coin that stands out in the crowded
              crypto market.
            </p>
          </div>
        </div>
      </div>

      {/* <div class="timeline">
        <div class="container left">
          <div class="date">15 Dec</div>
          <i class="icon fa fa-home"></i>
          <div class="content">
            <h2>Lorem ipsum dolor sit amet</h2>
            <p>
              Lorem ipsum dolor sit amet elit. Aliquam odio dolor, id luctus
              erat sagittis non. Ut blandit semper pretium.
            </p>
          </div>
        </div>
        <div class="container right">
          <div class="date">22 Oct</div>
          <i class="icon fa fa-gift"></i>
          <div class="content">
            <h2>Lorem ipsum dolor sit amet</h2>
            <p>
              Lorem ipsum dolor sit amet elit. Aliquam odio dolor, id luctus
              erat sagittis non. Ut blandit semper pretium.
            </p>
          </div>
        </div>
        <div class="container left">
          <div class="date">10 Jul</div>
          <i class="icon fa fa-user"></i>
          <div class="content">
            <h2>Lorem ipsum dolor sit amet</h2>
            <p>
              Lorem ipsum dolor sit amet elit. Aliquam odio dolor, id luctus
              erat sagittis non. Ut blandit semper pretium.
            </p>
          </div>
        </div>
        <div class="container right">
          <div class="date">18 May</div>
          <i class="icon fa fa-running"></i>
          <div class="content">
            <h2>Lorem ipsum dolor sit amet</h2>
            <p>
              Lorem ipsum dolor sit amet elit. Aliquam odio dolor, id luctus
              erat sagittis non. Ut blandit semper pretium.
            </p>
          </div>
        </div>
        <div class="container left">
          <div class="date">10 Feb</div>
          <i class="icon fa fa-cog"></i>
          <div class="content">
            <h2>Lorem ipsum dolor sit amet</h2>
            <p>
              Lorem ipsum dolor sit amet elit. Aliquam odio dolor, id luctus
              erat sagittis non. Ut blandit semper pretium.
            </p>
          </div>
        </div>
        <div class="container right">
          <div class="date">01 Jan</div>
          <i class="icon fa fa-certificate"></i>
          <div class="content">
            <h2>Lorem ipsum dolor sit amet</h2>
            <p>
              Lorem ipsum dolor sit amet elit. Aliquam odio dolor, id luctus
              erat sagittis non. Ut blandit semper pretium.
            </p>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Roadmap;
