// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* From Uiverse.io by ibrahimjlidi */
.loader {
  width: 48px;
  height: 48px;
  margin: auto;
  position: relative;
}

.loader:before {
  content: '';
  width: 48px;
  height: 5px;
  background: #253050;
  position: absolute;
  top: 60px;
  left: 0;
  border-radius: 50%;
  animation: shadow324 0.5s linear infinite;
}

.loader img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 40px;
  animation: jump7456 0.5s linear infinite;
}

@keyframes jump7456 {
  15% {
    border-bottom-right-radius: 0;
  }

  25% {
    transform: translateY(15px);
  }

  50% {
    transform: translateY(17px) scale(1, 0.9);
    border-bottom-right-radius: 40px;
  }

  75% {
    transform: translateY(4px) ;
  }

  100% {
    transform: translateY(4px);
  }
}

@keyframes shadow324 {
  0%,
  100% {
    transform: scale(2, 2);
  }

  50% {
    transform: scale(2, 1);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Infomation/style/icon-style.css"],"names":[],"mappings":"AAAA,oCAAoC;AACpC;EACE,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,WAAW;EACX,WAAW;EACX,mBAAmB;EACnB,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,kBAAkB;EAClB,yCAAyC;AAC3C;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,mBAAmB;EACnB,wCAAwC;AAC1C;;AAEA;EACE;IACE,6BAA6B;EAC/B;;EAEA;IACE,2BAA2B;EAC7B;;EAEA;IACE,yCAAyC;IACzC,gCAAgC;EAClC;;EAEA;IACE,2BAA2B;EAC7B;;EAEA;IACE,0BAA0B;EAC5B;AACF;;AAEA;EACE;;IAEE,sBAAsB;EACxB;;EAEA;IACE,sBAAsB;EACxB;AACF","sourcesContent":["/* From Uiverse.io by ibrahimjlidi */\n.loader {\n  width: 48px;\n  height: 48px;\n  margin: auto;\n  position: relative;\n}\n\n.loader:before {\n  content: '';\n  width: 48px;\n  height: 5px;\n  background: #253050;\n  position: absolute;\n  top: 60px;\n  left: 0;\n  border-radius: 50%;\n  animation: shadow324 0.5s linear infinite;\n}\n\n.loader img {\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  top: 0;\n  left: 0;\n  border-radius: 40px;\n  animation: jump7456 0.5s linear infinite;\n}\n\n@keyframes jump7456 {\n  15% {\n    border-bottom-right-radius: 0;\n  }\n\n  25% {\n    transform: translateY(15px);\n  }\n\n  50% {\n    transform: translateY(17px) scale(1, 0.9);\n    border-bottom-right-radius: 40px;\n  }\n\n  75% {\n    transform: translateY(4px) ;\n  }\n\n  100% {\n    transform: translateY(4px);\n  }\n}\n\n@keyframes shadow324 {\n  0%,\n  100% {\n    transform: scale(2, 2);\n  }\n\n  50% {\n    transform: scale(2, 1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
