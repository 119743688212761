import React from "react";
// import { Image } from "react-bootstrap";
import iconStyle from "./style/icon-style.css";
import logo from "../../assets/logo.png";
const About = () => {
  return (
    <div id="about" class="w-full max-w-screen-lg mx-auto text-center fadeInBottom">
      <div class="flex items-center pt-16">
        <span class="flex-grow border-t border-gray-700"></span>
        <span class="px-4">About</span>
        <span class="flex-grow border-t border-gray-700"></span>
      </div>
      {/* <div class="loader"><img src={logo} alt="icon"/></div> */}
      <div class="loader">
        <img src={logo} alt="icon" />
      </div>{" "}
      <div class="p-4">
        {/* <p class="text-[25px]"></p> */}
        <p class="text-[16px] leading-5 mt-2 text-[var(--secondary-color)]">
          MEON Coin is not just another cryptocurrency; it's a revolution in the
          world of meme coins. With a vibrant community and a playful spirit,
          MEON Coin combines the best of crypto technology with the fun and
          excitement of internet memes. Our mission is to create a valuable,
          community-driven coin that stands out in the crowded crypto market.
        </p>
      </div>
    </div>
  );
};
export default About;
