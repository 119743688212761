import React from "react";
// import * as s from "../../styles/global";

const Roadmap = (props) => {
  return (
    <div id="roadmap" class="w-full max-w-screen-lg mx-auto fadeInBottom">
      <div class="flex items-center py-16 fadeInBottom">
        <span class="flex-grow border-t border-gray-700"></span>
        <span class="px-4">Roadmap</span>
        <span class="flex-grow border-t border-gray-700"></span>
      </div>

      <div class="md:h-20 w-full flex flex-row text-[16px] mb-2 ">
        <div class="w-1/4 bg-[#abcefc] text-center flex justify-center items-center text-[rgb(71,115,173)] rounded-l-xl">
          <p>Q3-2024</p>
        </div>
        <div class="w-3/4 border-1 border-solid border-gray-700  flex justify-left items-center p-2  rounded-r-xl text-[var(--secondary-color)]">
          <p>
            Launch of MEON Coin, Initial Exchange Listings, and Community
            Building.
          </p>
        </div>
      </div>

      <div class="md:h-20 w-full flex flex-row text-[16px] mb-2 ">
        <div class="w-1/4 bg-[#b68dec] text-center flex justify-center items-center text-[#55377c] rounded-l-xl">
          <p>Q4-2024</p>
        </div>
        <div class="w-3/4 border-1 border-solid border-gray-700  flex justify-left items-center p-2  rounded-r-xl text-[var(--secondary-color)]">
          <p>
            Development of MEON Wallet and Decentralized Exchange (DEX)
            Integration.
          </p>
        </div>
      </div>

      <div class="md:h-20 w-full flex flex-row text-[16px] mb-2 ">
        <div class="w-1/4 bg-[#8ad897] text-center flex justify-center items-center text-[#42754b] rounded-l-xl">
          <p>Q1-2025</p>
        </div>
        <div class="w-3/4 border-1 border-solid border-gray-700  flex justify-left items-center p-2  rounded-r-xl text-[var(--secondary-color)]">
          <p>Partnership Announcements and Major Marketing Campaigns.</p>
        </div>
      </div>

      <div class="md:h-20 w-full flex flex-row text-[16px] ">
        <div class="w-1/4 bg-[#d8d78a] text-center flex justify-center items-center rounded-l-xl text-[#807e43]">
          <p>Q2-2025</p>
        </div>
        <div class="w-3/4 border-1 border-solid border-gray-700 flex justify-left items-center p-2  rounded-r-xl text-[var(--secondary-color)]">
          <p class="">
            Expansion of Use Cases and Continuous Community Growth.
          </p>
        </div>
      </div>
    </div>
  );
};
export default Roadmap;
